import { Link, useSearchParams } from '@remix-run/react';
import { useEffect, useMemo } from 'react';
import useSWR from 'swr';

import {
  type DtoBlock,
  type DtoBrand,
  type EnumsBlockSearchSortBy,
  EnumsBrandPredefinedBlockScenario,
} from '@lp-lib/api-service-client/public';
import { type BlockType, fromAPIBlockType } from '@lp-lib/game';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useSetQueryParams } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { booleanify } from '../../utils/common';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import { ErrorMessage, useTagBySlug } from '../Game/GameCenter';
import { BlockIcon } from '../icons/Block';
import { DeleteIcon } from '../icons/DeleteIcon';
import { InfoIcon } from '../icons/InfoIcon';
import { NewWindowIcon } from '../icons/NewWindowIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { Loading } from '../Loading';
import {
  BlockCover,
  BlockDocCard,
  BlockList,
  type BlockSearchFilterValues,
  ControlledBlockSearchFilters,
  useBlockListLoader,
} from '../RoutedBlock';
import { BlockUniverseCard, BrandCover } from './BrandCard';
import { BrandDiscovery } from './BrandDiscovery';
import {
  SearchBrandList,
  TaggedBrandList,
  UntaggedBrandList,
} from './BrandList';
import { BrandUtils } from './utils';

function BreadCrumbs(props: {
  text?: string;
  onClick: () => void;
  children: React.ReactNode;
}) {
  return (
    <div className='flex items-center gap-2'>
      <button
        type='button'
        onClick={props.onClick}
        className='btn text-xl text-secondary hover:text-primary hover:underline'
      >
        {props.text ?? 'Brands'}
      </button>
      <span className='text-white text-3xl'>/</span>
      <span className='text-white text-xl'>{props.children}</span>
    </div>
  );
}

function Container(props: { children?: React.ReactNode }) {
  return (
    <div className='w-full h-full text-white px-12.5 py-7.5 flex flex-col gap-10'>
      {props.children}
    </div>
  );
}

function BlockCard(
  props: BrandBlockPickerProps & { block: DtoBlock; brand: DtoBrand }
) {
  const { brand, block, pickedBlockIds, onPick, onRemove } = props;

  const picked = useMemo(
    () => pickedBlockIds.includes(block.id),
    [block.id, pickedBlockIds]
  );

  const handlePick = () => onPick(brand, block);
  const handleRemove = () => onRemove(block);
  const summary = useMemo(
    () => BlockKnifeUtils.Summary(fromAPIBlockTypes(block), true),
    [block]
  );

  return (
    <div className='w-full flex items-center justify-between gap-5 rounded-xl p-2'>
      <div className='flex-1 overflow-hidden flex items-center gap-5'>
        <div className='relative overflow-hidden flex-shrink-0'>
          <BlockCover doc={summary} className='w-22.5 h-12.5' />
        </div>

        <div className='flex-1 overflow-hidden flex flex-col gap-2'>
          <div
            className='text-sm font-medium truncate'
            title={summary.title ?? ''}
          >
            {summary.title}
          </div>
          <div className='flex items-center text-2xs gap-1'>
            <BlockIcon
              className='w-3.5 h-3.5 flex-shrink-0'
              blockType={fromAPIBlockType(block.type)}
            />
            <span className='text-secondary truncate'>{summary.subtitle}</span>
          </div>
        </div>
      </div>

      {picked ? (
        <button
          type='button'
          onClick={handleRemove}
          className='flex-shrink-0 btn-delete w-7.5 h-7.5 rounded flex items-center justify-center text-xl font-medium'
        >
          <DeleteIcon />
        </button>
      ) : (
        <button
          type='button'
          onClick={handlePick}
          className='flex-shrink-0 btn-primary w-7.5 h-7.5 rounded flex items-center justify-center text-xl font-medium'
        >
          +
        </button>
      )}
    </div>
  );
}

const SCENARIOS = [
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioInstructions,
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioOpeningTitle,
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioHostedInstructions,
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioDemo,
  EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard,
];

function BrandDetail(props: BrandBlockPickerProps & { brandId: string }) {
  const { brandId, onPick } = props;

  const {
    data: brand,
    isLoading,
    error,
    mutate,
  } = useSWR(
    `/brands/${brandId}`,
    async () => (await apiService.brand.get(brandId)).data.brand
  );
  const predefinedBlockSummary = useMemo(() => {
    return (
      <ul>
        {SCENARIOS.map((scenario) => {
          const maybeBlock = BrandUtils.GetPredefinedBlock(brand, scenario);
          return (
            <li key={scenario} className='text-xs text-icon-gray'>
              <span
                className={`${maybeBlock ? 'text-green-001' : 'text-red-003'}`}
              >
                {maybeBlock ? '✓' : '✗'}
              </span>{' '}
              {BrandUtils.PredefinedBlockScenarioDisplayName(scenario)}
            </li>
          );
        })}
      </ul>
    );
  }, [brand]);
  const otherBlocks = useMemo(() => BrandUtils.GetOtherBlocks(brand), [brand]);

  if (isLoading) return <Loading />;
  if (error || !brand)
    return (
      <div className='w-full flex items-center justify-center text-white my-30'>
        <ErrorMessage text='Something went wrong' handleRetry={() => mutate} />
      </div>
    );

  const handleAddAll = () => {
    if (!brand) return;
    onPick(brand, ...otherBlocks);
  };

  return (
    <div className='w-full h-full flex flex-col gap-10'>
      <header className='flex items-center gap-5'>
        <div className='w-70 h-43'>
          <BrandCover brand={brand} className='w-70 h-43' />
        </div>
        <div className='flex flex-col gap-3'>
          <div className='text-3.5xl font-bold flex items-center gap-4'>
            {brand.name}
            <Link
              to={`/admin/brands/${brand.id}`}
              target='_blank'
              rel='nofollow noreferrer noopener'
              className='text-icon-gray'
            >
              <NewWindowIcon className='w-5 h-5 fill-current' />
            </Link>
          </div>
          <button
            type='button'
            className='btn-primary w-45 h-10'
            onClick={handleAddAll}
            disabled={!otherBlocks.length}
          >
            Add All Blocks
          </button>
          <div>
            <div className='text-xs font-bold text-icon-gray'>
              Hard Coded Blocks
            </div>
            {predefinedBlockSummary}
          </div>
        </div>
      </header>

      <main className='w-full flex flex-col gap-8'>
        <section className='w-full flex flex-col gap-3'>
          <div className='text-2xl font-medium'>Blocks</div>
          <div>
            {otherBlocks.map((block) => (
              <BlockCard
                key={block.id}
                {...props}
                block={block}
                brand={brand}
              />
            ))}

            {otherBlocks.length === 0 && (
              <div className='w-full flex items-center justify-center text-white text-lg'>
                <ErrorMessage text={`No blocks`} />
              </div>
            )}
          </div>
        </section>
      </main>
    </div>
  );
}

function useContextSafeBlockSearchFilterValues() {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('block-search-type') as BlockType | null;
  const brandId = searchParams.get('block-search-brand-id');
  const sortBy = searchParams.get(
    'block-search-sort-by'
  ) as EnumsBlockSearchSortBy | null;
  const all = booleanify(searchParams.get('block-search-all'));

  const values = useMemo(
    () => ({
      type,
      brandId,
      sortBy,
      all,
    }),
    [type, brandId, sortBy, all]
  );

  const onChangeValues = useLiveCallback((values: BlockSearchFilterValues) => {
    if (values.type != null) {
      searchParams.set('block-search-type', values.type);
    } else {
      searchParams.delete('block-search-type');
    }
    if (values.brandId !== null) {
      searchParams.set('block-search-brand-id', values.brandId);
    } else {
      searchParams.delete('block-search-brand-id');
    }
    if (values.sortBy !== null) {
      searchParams.set('block-search-sort-by', values.sortBy);
    } else {
      searchParams.delete('block-search-sort-by');
    }
    if (values.all) {
      searchParams.set('block-search-all', 'true');
    } else {
      searchParams.delete('block-search-all');
    }
    setSearchParams(searchParams);
  });

  const onClearValues = useLiveCallback(() => {
    searchParams.delete('block-search-type');
    searchParams.delete('block-search-brand-id');
    searchParams.delete('block-search-sort-by');
    searchParams.delete('block-search-all');
    setSearchParams(searchParams);
  });

  return {
    values,
    onChangeValues,
    onClearValues,
  };
}

function BlockUniverse(
  props: BrandBlockPickerProps & { q?: string; enableFilters?: boolean }
) {
  const { pickedBlockIds } = props;
  const [, setFilter] = useBrandBlockFilter();

  const { values, onChangeValues, onClearValues } =
    useContextSafeBlockSearchFilterValues();

  useEffect(() => {
    return () => onClearValues();
  }, [onClearValues]);

  const loader = useBlockListLoader(
    props.q,
    useMemo(
      () => ({
        includeBrands: true,
        ...values,
        brandIds: values.brandId,
      }),
      [values]
    )
  );

  return (
    <div className='w-full flex flex-col gap-4'>
      {props.enableFilters && (
        <ControlledBlockSearchFilters
          defaultValues={values}
          onChange={onChangeValues}
        />
      )}
      <BlockList
        loader={loader}
        render={(doc) => {
          const picked = pickedBlockIds.includes(doc.block.id);
          return (
            <BlockDocCard
              doc={doc}
              q={props.q}
              showSubtitle
              menu={
                <div className='flex items-center justify-center gap-1'>
                  <button
                    type='button'
                    className={`${
                      picked ? 'btn-delete' : 'btn-primary'
                    } w-6 h-6 rounded flex justify-center items-center`}
                    onClick={() => {
                      if (picked) {
                        props.onRemove(doc.block);
                      } else {
                        props.onPick(doc.brand, doc.block);
                      }
                    }}
                  >
                    {picked ? <DeleteIcon /> : <PlusIcon />}
                  </button>
                  {doc.block.brandId ? (
                    <button
                      type='button'
                      className='btn-warning w-6 h-6 rounded flex justify-center items-center'
                      onClick={() =>
                        setFilter('brandId', doc.block.brandId ?? '', true)
                      }
                    >
                      <InfoIcon />
                    </button>
                  ) : (
                    <div className='w-6 h-6' />
                  )}
                </div>
              }
            />
          );
        }}
      />
    </div>
  );
}

type BrandBlockFilter = {
  tag: string | null;
  untagged: boolean;
  q: string | null;
  brandId: string | null;
  pageSize: number;
  gridTemplateColumns: number;
  blockUniverse: boolean;
  editingBlockId: string | null;
};

type FilterNames = keyof BrandBlockFilter;

export function useBrandBlockFilter(): [
  BrandBlockFilter,
  (key: FilterNames, val: string | null, clear?: boolean) => void,
  () => void
] {
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');
  const tag = searchParams.get('tag');
  const untagged = Boolean(searchParams.get('untagged'));
  const brandId = searchParams.get('brandId');
  const pageSize = Number(searchParams.get('pageSize')) || 96;
  const gridTemplateColumns =
    Number(searchParams.get('gridTemplateColumns')) || 2;
  const blockUniverse = Boolean(searchParams.get('blockUniverse'));
  const editingBlockId = searchParams.get('editingBlockId');
  const setQueryParams = useSetQueryParams();

  const emtpy = useInstance(() => [
    { key: 'tag', value: null },
    { key: 'untagged', value: null },
    { key: 'q', value: null },
    { key: 'brandId', value: null },
    { key: 'pageSize', value: null },
    { key: 'gridTemplateColumns', value: null },
    { key: 'blockUniverse', value: null },
  ]);

  return [
    {
      q,
      tag,
      untagged,
      brandId,
      pageSize,
      gridTemplateColumns,
      blockUniverse,
      editingBlockId,
    },
    useLiveCallback((key: FilterNames, val: string | null, clear?: boolean) => {
      if (clear) {
        setQueryParams([...emtpy, { key, value: val }]);
      } else {
        setQueryParams([{ key, value: val }]);
      }
    }),
    useLiveCallback(() => {
      setQueryParams(emtpy);
    }),
  ];
}

export interface BrandBlockPickerProps {
  pickedBlockIds: string[];
  onPick: (brand?: DtoBrand, ...blocks: DtoBlock[]) => void;
  onRemove: (...block: DtoBlock[]) => void;
}

export function BrandBlockPicker(props: BrandBlockPickerProps) {
  const [
    {
      q,
      tag: tagSlug,
      untagged,
      brandId,
      pageSize,
      gridTemplateColumns,
      blockUniverse,
    },
    setFilter,
    clearFilter,
  ] = useBrandBlockFilter();

  const { data: tag } = useTagBySlug(tagSlug);

  if (brandId) {
    return (
      <Container>
        <BreadCrumbs onClick={clearFilter} children={'Brand Detail'} />
        <BrandDetail brandId={brandId} {...props} />
      </Container>
    );
  }

  if (blockUniverse && q) {
    return (
      <Container>
        <BreadCrumbs
          onClick={clearFilter}
          children={
            <BreadCrumbs
              text='Block Universe'
              onClick={() => setFilter('q', null)}
              children={`Search Results: ${q}`}
            />
          }
        />
        <BlockUniverse {...props} q={q} enableFilters />
      </Container>
    );
  }

  if (blockUniverse) {
    return (
      <Container>
        <BreadCrumbs onClick={clearFilter} children='Block Universe' />
        <BlockUniverse {...props} enableFilters />
      </Container>
    );
  }

  if (q) {
    return (
      <Container>
        <BreadCrumbs onClick={clearFilter} children={`Search Results: ${q}`} />
        <SearchBrandList
          query={q}
          pageSize={pageSize}
          gridTemplateColumns={gridTemplateColumns}
          editable={false}
          onClick={(brand) => setFilter('brandId', brand.id, true)}
        />
      </Container>
    );
  }

  if (untagged) {
    return (
      <Container>
        <BreadCrumbs onClick={() => setFilter('untagged', null)}>
          Untagged
        </BreadCrumbs>
        <UntaggedBrandList
          gridTemplateColumns={gridTemplateColumns}
          pageSize={pageSize}
        />
      </Container>
    );
  }

  if (tagSlug) {
    if (!tag) return <Loading />;
    return (
      <Container>
        <BreadCrumbs
          onClick={() => setFilter('tag', null)}
          children={tag.name}
        />
        <TaggedBrandList
          gridTemplateColumns={gridTemplateColumns}
          pageSize={pageSize}
          tagId={tag.id}
        />
      </Container>
    );
  }

  return (
    <Container>
      <header>
        <p className='text-3.5xl font-bold'>Select blocks to include</p>
        <p className='text-base font-normal'>
          Select blocks from the Block Universe or from an existing brand.
        </p>
      </header>

      <BlockUniverseCard onClick={() => setFilter('blockUniverse', 'true')} />

      <BrandDiscovery
        gridTemplateColumns={gridTemplateColumns}
        pageSize={pageSize}
      />
    </Container>
  );
}
