import { useNavigate } from '@remix-run/react';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import useSWR from 'swr';

import {
  type DtoBrand,
  EnumsBrandPredefinedBlockScenario,
} from '@lp-lib/api-service-client/public';
import { MediaType } from '@lp-lib/media';

import placeholder from '../../assets/img/placeholder/game-cover.png';
import { apiService } from '../../services/api-service';
import { fromMediaDTO } from '../../utils/api-dto';
import { ImagePickPriorityLowToHigh, MediaUtils } from '../../utils/media';
import { type Action, ActionSheet } from '../ActionSheet';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { DeleteIcon } from '../icons/DeleteIcon';
import { DuplicateIcon } from '../icons/DuplicateIcon';
import { EditIcon } from '../icons/EditIcon';

export function BrandMenu(props: {
  brand: DtoBrand;
  onDuplicate?: (brand: DtoBrand) => void;
  onDelete?: () => void;
}): JSX.Element {
  const { brand, onDuplicate, onDelete } = props;

  const navigate = useNavigate();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'edit',
      icon: <EditIcon />,
      text: 'Edit',
      onClick: () => {
        navigate(`/admin/brands/${brand.id}`);
      },
    },
    {
      kind: 'button',
      key: 'duplicate',
      icon: <DuplicateIcon />,
      text: 'Duplicate',
      onClick: async () => {
        const response = await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='px-5 py-2'>
              <ConfirmCancelModalHeading>
                {`Duplicate ${brand.name}`}
              </ConfirmCancelModalHeading>
              <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
                Duplicating a brand duplicates its metadata and associated
                hard-coded blocks, but not other blocks.
              </ConfirmCancelModalText>
            </div>
          ),
          confirmBtnLabel: 'Duplicate',
          confirmBtnVariant: 'primary',
        });
        if (response.result !== 'confirmed') return;

        const resp = await apiService.brand.duplicate(brand.id);
        if (onDuplicate) {
          onDuplicate(resp.data.brand);
        }
      },
    },
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: async () => {
        const response = await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='px-5 py-2'>
              <ConfirmCancelModalHeading>
                {`Delete ${brand.name}`}
              </ConfirmCancelModalHeading>
              <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
                Deleting a brand detaches all blocks, including hard-coded ones,
                from it, but does not delete the blocks themselves.
              </ConfirmCancelModalText>
            </div>
          ),
          confirmBtnLabel: 'Delete',
          confirmBtnVariant: 'delete',
        });
        if (response.result !== 'confirmed') return;

        await apiService.brand.delete(brand.id);
        if (onDelete) {
          onDelete();
        }
      },
    },
  ];

  return (
    <ActionSheet
      actions={actions}
      btnSizingClassName='w-7.5 h-7.5'
      placement='bottom-end'
    />
  );
}

export function BrandCover(props: {
  brand: DtoBrand;
  className?: string;
}): JSX.Element {
  const { brand, className } = props;

  const src = useMemo(() => {
    const media = fromMediaDTO(brand.showcaseMedia);

    if (!media) return null;
    if (media.type === MediaType.Image) {
      return MediaUtils.PickMediaUrl(media, {
        priority: ImagePickPriorityLowToHigh,
      });
    }
    if (media.type === MediaType.Video) {
      return media.firstThumbnailUrl;
    }
  }, [brand.showcaseMedia]);

  return (
    <img
      src={src || placeholder}
      alt='cover'
      className={`object-cover border border-secondary rounded-lg w-full h-full ${className}`}
    />
  );
}

export function BrandCard(props: {
  brand: DtoBrand;
  menu?: React.ReactNode;
  onClick?: () => void;
}): JSX.Element {
  const { brand, menu, onClick } = props;

  return (
    <div
      className={`min-w-90 h-15 p-2 ${
        onClick ? 'hover:bg-lp-gray-002 cursor-pointer' : ''
      } rounded-xl flex items-center gap-4`}
      onClick={onClick}
    >
      <div className='flex-1 overflow-hidden flex items-center gap-4'>
        <div className='flex-none w-22 h-12'>
          <BrandCover brand={brand} />
        </div>

        <div className='flex-1 overflow-hidden'>
          <p title={brand.name} className='text-base font-bold truncate'>
            {brand.name}
          </p>
          <p className='text-sms font-normal'>
            <span>
              {brand.predefinedBlockData?.length ?? 0}/
              {Object.keys(EnumsBrandPredefinedBlockScenario).length} Hardcoded
            </span>
            <span className='text-white mx-1.5'>·</span>
            <span>
              {pluralize('Attached Block', brand.blockIds?.length ?? 0, true)}
            </span>
          </p>
        </div>
      </div>
      {menu}
    </div>
  );
}

export function BlockUniverseCard(props: { onClick?: () => void }) {
  const { onClick } = props;

  const { data: count = 0 } = useSWR(
    '/blocks/count',
    async () => (await apiService.block.count()).data.count
  );

  return (
    <div
      onClick={onClick}
      className={`w-90 h-15 p-2 ${
        onClick ? 'hover:bg-lp-gray-002 cursor-pointer' : ''
      }  rounded-xl flex items-center gap-4`}
    >
      <div className='flex-none w-22 h-12'>
        <img
          src={placeholder}
          alt='cover'
          className={`object-cover border border-secondary rounded-lg flex-shrink-0 w-full h-full`}
        />
      </div>
      <div>
        <div className='text-base font-bold'>Block Universe</div>

        <div className='text-sms font-normal'>
          {count
            ? `${count.toLocaleString()} ${pluralize('Block', count, false)}`
            : null}
        </div>
      </div>
    </div>
  );
}
